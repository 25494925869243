import React from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Grid, LinearProgress, MobileStepper, Typography } from '@mui/material';
import { Box } from '@mui/system';

const StepperDrawer = ({
  open,
  onClose,
  steps,
  currentStep,
  PaperProps,
}) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={PaperProps}
  >
    <Box justifyContent="center">
      <MobileStepper
        variant="dots"
        position="static"
        steps={steps.length}
        activeStep={currentStep}
        sx={{ pt: 3, justifyContent: 'center' }}
      />
      {steps[currentStep].loading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography variant="h4" textAlign="center" my={3}>
            {steps[currentStep].title}
          </Typography>
          {steps[currentStep].Component}
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
          >
            {steps[currentStep].onBack && (
              <Button
                onClick={steps[currentStep].onBack}
                sx={{ textDecoration: 'underline',
                  borderRadius: 4,
                  px: 3 }}
              >
                Atrás
              </Button>
            )}
            {steps[currentStep].onNext && (
              <Button
                variant="contained"
                onClick={steps[currentStep].onNext}
                size="medium"
                sx={{
                  borderRadius: 4,
                  px: 3,
                }}
              >
                {steps[currentStep].nextButtonText || 'Avanzar'}
              </Button>
            )}
          </Grid>
        </>
      )}
    </Box>
  </Drawer>
);

StepperDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    Component: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    nextButtonText: PropTypes.string,
  })).isRequired,
  currentStep: PropTypes.number.isRequired,
  PaperProps: PropTypes.objectOf(PropTypes.string),
};

StepperDrawer.defaultProps = {
  PaperProps: {},
};

export default StepperDrawer;
